<template>
  <base-section
    id="experience-capabilities"
  >
    <base-section-heading title="Core Competencies">
      <h3>
      Revolutionizing the manufacturing industry in accordance to the Industry Revolution 4.0 by providing intelligent factory solutions through automation and robotic technology.
      </h3>
    </base-section-heading>
    <v-img
      :src="require('@/assets/core_competencies.jpg')"
      contain
    />
    <v-container>

    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionExperienceCapabilities',

    data: () => ({
      experiences: [
        {
          title: 'Modern Informatization',
          icon: 'mdi-calendar',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Mechanical Automation',
          icon: 'mdi-factory',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Centralized Operation',
          color: 'primary',
          dark: true,
          icon: 'mdi-earth',
          text: '',
        },
        {
          title: 'Time Saving',
          icon: 'mdi-flask-empty-outline',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Unit Loading',
          icon: 'mdi-certificate',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Advance Planing',
          icon: 'mdi-hand-back-right-outline',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Labor Force Reduction',
          icon: 'mdi-hand-back-right-outline',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Streamline Process',
          icon: 'mdi-hand-back-right-outline',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Three Dimensional Space',
          icon: 'mdi-hand-back-right-outline',
          dark: true,
          color: 'primary',
          text: '',
        },
        {
          title: 'Shortest Distance',
          icon: 'mdi-hand-back-right-outline',
          dark: true,
          color: 'primary',
          text: '',
        },
      ],
    }),
  }
</script>
